<template>
    <div class="payTypeEdit">
        <el-card>
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form ref="form" :model="payType" label-width="80px" size="small">
                <el-form-item label="名称">
                    <el-input v-model="payType.name" style="width: 200px" readonly />
                </el-form-item>
                <el-form-item label="机构">
                    <el-input v-model="payType.deptName" style="width: 200px" readonly />
                </el-form-item>
                <el-form-item label="可支付(收银)">
                    <el-switch v-model="payType.canPay" disabled />
                </el-form-item>
                <el-form-item label="可退款(收银)">
                    <el-switch v-model="payType.canRechargeRefund" disabled />
                </el-form-item>
                <el-form-item label="可抹零(收银)">
                    <el-switch v-model="payType.canFloor" disabled />
                </el-form-item>
                <el-form-item label="可组合支付(收银)">
                    <el-switch v-model="payType.canCombinationPay" disabled />
                </el-form-item>
                <el-form-item label="可充值(会员)">
                    <el-switch v-model="payType.canRecharge" disabled />
                </el-form-item>
                <el-form-item label="可退款(会员)">
                    <el-switch v-model="payType.canRefund" disabled />
                </el-form-item>
                <el-form-item label="启用">
                    <el-switch v-model="payType.enable" />
                </el-form-item>
            </el-form>
        </el-card>
        <el-card shadow="never">
            <el-button type="primary" @click="handleSave" size="small">保存</el-button>
        </el-card>
    </div>
</template>

<script>
import UrlUtils from '../../../../js/UrlUtils';

export default {
    name: 'PayTypeEdit',
    props: ['form'],
    data() {
        return {
            payType: {
                canRefund: this.form.template.canRefund,
                canPay: this.form.template.canPay,
                canFloor: this.form.template.canFloor,
                canCombinationPay: this.form.template.canCombinationPay,
                canRecharge: this.form.template.canRecharge,
                canRechargeRefund: this.form.template.canRechargeRefund,
                name: this.form.template.name,
                enable: this.form.enable,
                deptName: this.form.deptName,
                deptCode: this.form.deptCode,
            },
            url: {
                batchSave: '/finance/pay/payType/patch_c/',
            },
        };
    },
    methods: {
        handleSave() {
            UrlUtils.PatchRemote(this, this.url.batchSave + this.form.code, this.payType);
        },
    },
};
</script>

<style scoped>
.BatchCreate .el-form-item {
    margin-bottom: 0;
}

.BatchCreate .el-table th.gutter {
    display: table-cell;
}
</style>
